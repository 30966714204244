import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import SEO from "./../components/seo"
import Profile from "../components/profile"

const NotFoundPage = () => (
  <div>
    <SEO title="404: page not found" description="This page doesn't exists." />
    <Profile />
    <MyText
      as={"h1"}
      style={{
        "--font-size": "4rem",
        "--margin-bottom": "4rem",
        "--text-shadow": "0px 7px 4px black",
        "--text-shadow-hover": "none",
      }}
    >
      404 - PAGE NOT FOUND
    </MyText>
  </div>
)

export default NotFoundPage

const MyText = styled.p`
  cursor: default;
  font-size: var(--font-size);
  color: #cccccc;
  margin-bottom: var(--margin-bottom);
  text-shadow: var(--text-shadow);
  text-align: center;

  &:hover {
    text-shadow: var(--text-shadow-hover);
  }
`
